function Footer() {
  return (
    <>
      <section className="body-parallax">
        <div className="container">
          <div className="py-4">
            <div className="row">
              <div className="col-lg-6 col-12"></div>
              <div className="col-lg-6 col-12">
                <div className="d-flex justify-content-end">
                  <i className="bi bi-facebook text-white fs-4"></i>
                  <i className="bi bi-twitter-x text-white fs-4 ms-3"></i>
                  <i className="bi bi-instagram text-white fs-4 ms-3"></i>
                  <i className="bi bi-linkedin text-white fs-4 ms-3"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
