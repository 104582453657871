import Header from "./Header";
import Footer from "./Footer";

function Portfolio() {
  const images = [
    { name: "1", extension: "png" },
    { name: "2", extension: "jpg" },
    { name: "3", extension: "jpg" },
    { name: "4", extension: "png" },
    { name: "5", extension: "png" },
    { name: "6", extension: "jpg" },
    { name: "7", extension: "png" },
    { name: "9", extension: "png" },
    { name: "10", extension: "png" },
    { name: "11", extension: "png" },
    { name: "12", extension: "png" },
    { name: "13", extension: "png" },
    { name: "14", extension: "png" },
    { name: "15", extension: "png" },
    { name: "16", extension: "png" },
    { name: "17", extension: "png" },
    { name: "18", extension: "png" },
    { name: "19", extension: "png" },
    { name: "20", extension: "png" },
    { name: "21", extension: "png" },
    { name: "22", extension: "png" },
    { name: "23", extension: "png" },
    { name: "24", extension: "png" },
    { name: "25", extension: "png" },
    { name: "26", extension: "png" },
  ];
  return (
    <>
      <Header />

      <section className="d-flex align-items-center">
        <video class="vh-100 w-100 object-fit-cover" autoPlay muted loop>
          <source src="assets/videos/4.mp4" type="video/mp4" />
        </video>
      </section>

      <section>
        <div>
          <div className="row g-0">
            {images.map((image, index) => (
              <div className="col-lg-4 col-md-6 col-12">
                <img
                  key={index}
                  className="portfolio-image w-100"
                  src={`assets/portfolio/${image.name}.${image.extension}`}
                  alt={`${image.name}.${image.extension}`}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Portfolio;
