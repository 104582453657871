import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Services() {
  return (
    <>
      <Header />

      <div
        id="carouselExampleAutoplaying"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="assets/services.jpg"
              class="d-block vh-100 w-100 object-fit-cover"
              alt="..."
            />
          </div>
        </div>
      </div>

      <section className="bg-white pb-5">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-6 col-12">
              <div className="pe-lg-5 pe-md-4 pe-0 mt-5">
                <h2 className="fw-light text-black display-5">
                  Branding is the epicentre of all that we do
                </h2>
                <p className="mt-5">
                  We're a team of creative wizards, with a passion for crafting
                  enchanting brand adventures. We sprinkle our magic on ideas,
                  transforming them into captivating stories, which then blossom
                  into beloved brands, sparking joyous communities. Guided by
                  strategy, fueled by storytelling, and armed with an array of
                  services, we're here to make your brand journey a whimsical
                  delight
                </p>
                <Link
                  to="/about"
                  className="btn btn-outline-dark px-5 py-3 rounded-pill my-5"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header bg-transparent">
                    <button
                      class="accordion-button collapsed bg-light-black"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      Strategy
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-5">
                      <ul>
                        <li>Brand Strategy</li>
                        <li>Verbal Identity</li>
                        <li>Brand Naming</li>
                        <li>Go-To-Market</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button bg-light-black collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Branding
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-5">
                      <ul>
                        <li>Brand Identity</li>
                        <li>Packaging Design</li>
                        <li>Creative Consultancy</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button bg-light-black collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Digital
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-5">
                      <ul>
                        <li>Website Design</li>
                        <li>Website Development</li>
                        <li>Performance Marketing</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button bg-light-black collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Content
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-5">
                      <ul>
                        <li>Copywriting</li>
                        <li>Photography + Videography</li>
                        <li>Motion + Animation</li>
                        <li>Socials</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax-9 d-flex align-items-center"></section>

      <Footer />
    </>
  );
}

export default Services;
