import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Header />

      <div
        id="carouselExampleAutoplaying"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="assets/5.png"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets/new/1.webp"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets/new/2.webp"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets/new/3.webp"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets/new/4.webp"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets/home/1.png"
              class="d-block w-100 vh-100 object-fit-cover object-position-bottom"
              alt="..."
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <section className="body-parallax">
        <div className="container py-5">
          <div className="mx-lg-5 px-lg-5 px-4 text-center py-5">
            <h1 className="text-white mx-2">
              We build brands larger than life.
            </h1>
            <h5 className="mt-4 text-white fw-light mx-2">
              Welcome to the forefront of brand innovation, where we tailor
              exceptional identities for the forward-thinking. Whether you're
              venturing into the unknown or dominating your field, we reshape
              entire industries and propel growth through a fusion of insight,
              strategy, and compelling narratives. Let's embark on this journey
              together and sculpt a future brimming with success for your brand.
            </h5>
          </div>
        </div>
      </section>

      <section className="parallax-1"></section>

      <section className="body-parallax">
        <div className="container py-5">
          <div className="mx-lg-5 px-lg-5 px-4 text-center py-5">
            <h1 className="text-white display-5 fw-bold">The Road Ahead …</h1>
          </div>
        </div>
      </section>

      <section className="d-flex align-items-center">
        <video
          class="w-100 home-video-section object-fit-cover"
          autoPlay
          muted
          loop
        >
          <source src="assets/videos/berry.mp4" type="video/mp4" />
        </video>
      </section>

      <section className="parallax-3 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 py-5 my-5">
              <h1 className="text-white display-2 fw-bold" data-aos="fade-up">
                Zest
              </h1>
              <h5 className="mt-4 text-white fw-light">
                We thrive on excitement, quick thinking, and adaptability,
                embracing the world's diversity and unpredictability. Our goal
                is to create work that inspires, surprises, and brings joy,
                sparking meaningful conversations and connections.
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax-4 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-lg-block d-none"></div>
            <div className="col-lg-6 col-12 py-5 my-5">
              <h1 className="text-white display-2 fw-bold" data-aos="fade-up">
                Growth
              </h1>
              <h5 className="mt-4 text-white fw-light">
                We gauge success by how well things work and how much money they
                make. But growth is also about exploring new places, trying new
                ideas, reaching more people, and making new things happen.
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax-5 d-flex align-items-center">
        <div className="container">
          <div className="py-5 my-5">
            <h1 className="text-white display-2 fw-bold" data-aos="fade-up">
              Innovation
            </h1>
          </div>
        </div>
      </section>

      <section className="parallax-7 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 py-5 my-5">
              <h1 className="text-white display-2 fw-bold" data-aos="fade-up">
                Our Work
              </h1>
              <h5 className="mt-4 text-white fw-light">
                Brands that live in the hand, heart and mind…
              </h5>
              <Link
                to="/portfolio"
                className="btn btn-outline-light px-5 py-2 rounded-pill mt-4"
              >
                Explore Work
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <div
        id="carouselExampleFade"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bottom-slider-1 d-flex align-items-center">
            <div className="container text-center">
              <h1 className="text-white display-2 fw-bold">Growth</h1>
              <h5 className="mt-4 text-white fw-light">
                We’re measured by effectiveness and commercial success.
              </h5>
            </div>
          </div>
          <div class="carousel-item bottom-slider-2 d-flex align-items-center">
            <div className="container text-center">
              <h1 className="text-white display-2 fw-bold">Our Work</h1>
              <h5 className="mt-4 text-white fw-light">
                We’re measured by effectiveness and commercial success.
              </h5>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}

      <Footer />
    </>
  );
}

export default Home;
