import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <Header />

      <div
        id="carouselExampleAutoplaying"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="assets/2.webp"
              height="530"
              class="d-block w-100 vh-100 object-fit-cover object-position-top"
              alt="..."
            />
          </div>
        </div>
      </div>

      <section className="bg-white py-5">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-6 col-12">
              <div className="pe-lg-5 pe-md-4 pe-0">
                <h2 className="fw-light display-4">Berrylicious Tales</h2>
                <Link
                  to="/services"
                  className="btn btn-outline-dark px-5 py-3 rounded-pill my-5"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="ps-lg-5 ps-md-4 ps-0">
                <p className="mt-2">
                  Welcome to Cranberry Digital Media, where innovation fuels our
                  passion for creating impactful digital experiences. Our
                  mission is simple: to harness the power of creativity and
                  technology to elevate your brand and drive success in the
                  digital realm.
                </p>
                <p className="mt-4">
                  At Cranberry, we understand that in today's fast-paced world,
                  standing out requires more than just a website or a social
                  media presence. It demands innovation—bold ideas that
                  captivate, engage, and inspire. That's why we're dedicated to
                  pushing boundaries, exploring uncharted territories, and
                  delivering solutions that exceed expectations.
                </p>
                <p className="mt-4">
                  From stunning website designs to immersive multimedia
                  campaigns, we're here to help you navigate the ever-evolving
                  digital landscape with confidence. Let's embark on this
                  journey together, transforming your brand's vision into
                  reality and reaching new heights of success. Welcome to
                  Cranberry Digital Media—where innovation meets excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax-8 d-flex align-items-center"></section>

      <section className="bg-white py-5">
        <div className="container py-5">
          <div className="p-5">
            <h2 className="fw-light display-7 ff-arial">
              Our expertise spans different fields, but at our core, we're all
              about creating captivating brand experiences. We specialize in
              turning ideas into stories, stories into beloved brands, and
              brands into vibrant cultures with dedicated followings. This is
              made possible through strategic planning, skilled storytelling,
              and a range of services we offer
            </h2>
          </div>

          <div className="row mt-5 pt-3">
            <div className="col-12 px-lg-5 px-0 d-flex align-items-center">
              <img src="assets/logo-lg.png" className="w-100" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-white pb-5">
        <div className="container pt-5">
          <div className="row py-5">
            <div className="col-lg-6 col-12">
              <div className="pe-lg-5 pe-md-4 pe-0">
                <h2 className="fw-light display-4">Our Superpowers</h2>
                <div className="row mt-5">
                  <div className="col-lg-6 col-12">
                    <p>
                      <i className="bi bi-check-lg"></i> Branding Marvels
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Ad-Wonders
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Digital Dynamo
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Design & Content
                      Brilliance
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Strategic Sorcery
                    </p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <p>
                      <i className="bi bi-check-lg"></i> Creativity Unleashed
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Client Love
                    </p>
                    <p>
                      <i className="bi bi-check-lg"></i> Innovation Junkies
                    </p>
                  </div>
                </div>
                <Link
                  to="/contact"
                  className="btn btn-outline-dark px-5 py-3 rounded-pill my-5"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="ps-lg-5 ps-md-4 ps-0">
                <p className="mt-2">
                  <b className="h3">Brand Recognition</b> : 89% of consumers
                  stay loyal to brands that share their values.
                </p>
                <p className="mt-4">
                  <b className="h3">Brand Trust</b> : 81% of consumers say that
                  trust in a brand is a deal-breaker or deciding factor in their
                  purchasing decision.
                </p>
                <p className="mt-4">
                  <b className="h3">Online Presence</b> : 81% of shoppers
                  conduct online research before making a purchase.
                </p>
                <h2 className="fw-light mt-5">Customer Loyalty</h2>
                <p>
                  55% of consumers are more likely to buy from a brand they
                  follow on social media.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
}

export default About;
