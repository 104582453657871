import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Menu() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 8 * 16) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className="container pt-1">
        <div>
          <Link to="/">
            <img src="assets/close.png" width="25" className="me-3" />
          </Link>

          <img
            src="assets/logo.png"
            width="180"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center mt-5 pt-4 col-lg-8 col-12">
            <h2
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="500"
              onClick={() => navigate("/")}
              className="my-title fw-bold m-0 cursor-pointer text-center"
            >
              Home &nbsp; &nbsp;
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-duration="400"
              onClick={() => navigate("/about")}
              className="my-title fw-bold m-0 cursor-pointer text-center"
            >
              About Us &nbsp; &nbsp;
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-offset="400"
              data-aos-duration="300"
              onClick={() => navigate("/portfolio")}
              className="my-title fw-bold m-0 cursor-pointer text-center"
            >
              Our Portfolio &nbsp; &nbsp; &nbsp;
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-duration="500"
              onClick={() => navigate("/services")}
              className="my-title fw-bold m-0 cursor-pointer text-center"
            >
              What We Offer &nbsp; &nbsp;
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="400"
              onClick={() => navigate("/contact")}
              className="my-title fw-bold m-0 cursor-pointer text-center"
            >
              &nbsp; &nbsp; &nbsp; &nbsp; Contact Us
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
